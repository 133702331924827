import Vue from 'vue'
import Router from 'vue-router'
import store from "./store/"
import { myCookies } from "./helper"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: "/app",
      component: () => import(/* webpackChunkName: "about" */ './views/Home.vue'),
      children: [
        {
          path: "app/:page?",
          name: "mykmzero",
          component: () => import(/* webpackChunkName: "accounts" */'./views/My.vue'),
          props: true
        },
        {
          path: 'login',
          name: 'login',
          component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue'),
        }
      ],
      beforeEnter: (to, from, next) => {
        console.log(to.name, from.name);
        store.dispatch("layout/setIsNew", myCookies.get("new"));
        if(to.name != "login") {
          if(store.getters["auth/isLoggedIn"]) {
            next();
          } else {
            console.log("setting before login:", to.fullPath);
            store.dispatch("auth/setNextPath", to.fullPath, {root: true});
            next({name: "login"});
          }
        } else {
          console.log("going to", to.name);
          next();
        }
      }
    },
    {
      path: "/new",
      name: "new",
      beforeEnter: (to, from, next) => {
        console.log("ROUTER: setting new cookie");
        myCookies.set("new", true);
        next({name: "home"});
      }
    },
    {
      path: "/old",
      name: "old",
      beforeEnter: (to, from, next) => {
        console.log("ROUTER: unsetting new cookie");
        myCookies.set("new", false);
        next({name: "home"});
      },
    }
  ]
})
  

export default router;