import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Beufy from "buefy"
import $ from "jquery"
import filters from "./assets/js/filters"
import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies';
import CountryFlag from 'vue-country-flag';



import 'buefy/dist/buefy.css'

import "@/css/athletics.scss";

Vue.config.productionTip = false

Vue.use(VueGtag, {
  appName: 'myKMZ',
  pageTrackerScreenviewEnabled: true,
  config: { id: 'G-6CWPBX1C42' }
}, router);

window.$ = Vue.prototype.$ = $;

Vue.use(filters);
Vue.use(Beufy.default);
Vue.use(VueCookies);
Vue.component('country-flag', CountryFlag);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
