import * as XLSX from 'xlsx';

export const sheets = {
    namespaced: true,
    state: {},
    getters: {}, // Added empty getters object
    mutations: {},
    actions: {
        exportSheet: async function (payload, filename = null, format = "xlsx") {
            // Default filename to current date if none is provided
            if (!filename) {
                filename = "kmzero_export_" + new Date().toISOString().split("T")[0];
            }
            
            let headers = payload.headers;
            let data = payload.data;

            console.log(headers);
            console.log(data);

            // Combine headers and data into a single array (2D array format)
            const sheetData = [headers, ...data];

            // Create worksheet from the 2D array
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

            // Create workbook and append worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "kmzero");

            // Generate the file as a Blob in memory and initiate download
            const blob = XLSX.write(workbook, { bookType: format, type: "blob" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${filename}.${format}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return filename;
        }
    }
};