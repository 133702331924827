import Vue from "vue";
import { terms } from "@/assets/js/lang.js";

export const lang = {
    namespaced: true,
    state: {
        currentLang: "en",
        languages: {
            en: {
                name: "en",
                title: "English"
            },
            es: {
                name: "es",
                title: "Español"
            }
        },
        terms: terms
    },
    getters: {
        getLang: (state) => {
            return state.currentLang;
        },
        translate: (state) => (term, lang) => {
            if(!lang) lang = state.currentLang;
            if(!term) return "";

            var foundTerm = state.terms[term];
            return foundTerm ? foundTerm[lang] || term : term;
        }
    },
    mutations: {
        SET_LANG: (state, lang) => {
            Vue.set(state, "currentLang", lang);
        }
    },
    actions: {
        setLang: (context, lang) => {
            if(!lang) lang = Vue.$cookies.get("lang");
            if(!lang) lang = "en";
            Vue.$cookies.set("lang", lang);
            context.commit("SET_LANG", lang);
        },
        setup: (context) => {
            Vue.filter('translate', function(text, lang) {
                return context.getters.translate(text, lang);
            })
            console.log("filter set");
            context.dispatch("setLang");
        }
    }
}