module.exports = {
    terms : {
        "username_placeholder": {
            en: "username",
            es: "nombre de usuario"
        },
        "password_placeholder": {
            en: "password",
            es: "contraseña"
        },
        "sign_in_button_text": {
            en: "sign in",
            es: "iniciar sesión"
        },
        "sign_out_button_text": {
            en: "sign out",
            es: "cerrar sesión"
        },
        "english": {
            en: "english",
            es: "inglés"
        },
        "spanish": {
            en: "spanish",
            es: "español"
        },
        "equipment_label": {
            en: "equipment",
            es: "equipo"
        },
        "equipment_subtitle": {
            en: "Manage installed equipment",
            es: "Gestiona todos los equipos instalados"
        },
        "equipment_button_text": {
            en: "Discover",
            es: "Descubrir"
        },
        "bottles_label": {
            en: "bottles",
            es: "botellas"
        },
        "bottles_subtitle": {
            en: "Request replenishment and more",
            es: "Solicita reposición y más"
        },
        "bottles_button_text": {
            en: "Discover",
            es: "Descubrir"
        },
        "kmzero_label": {
            en: "kmzero",
            es: "kmzero"
        },
        "kmzero_subtitle": {
            en: "You need help? Find it here",
            es: "Necesitas ayuda, encuentrala aquí"
        },
        "kmzero_button_text": {
            en: "Discover",
            es: "Descubrir"
        },
        "impact_label": {
            en: "impact+",
            es: "impacto+"
        },
        "impact_subtitle": {
            en: "This is your impact on the planet",
            es: "Esto es lo que estas haciendo por el planeta"
        },
        "impact_button_text": {
            en: "Discover",
            es: "Descubrir"
        },
        "city_centre_label": {
            en: "Geographical Location",
            es: "Ubicación Geográfica"
        },
        "centre_label": {
            en: "Centre",
            es: "Centro"
        },
        "maintenance_label": {
            en: "Maintenance",
            es: "Mantenimiento"
        },
        "location_label": {
            en: "Location",
            es: "Ubicación"
        },
        "dispenser_label": {
            en: "Dispenser",
            es: "Modelo"
        },
        "equipment_id_label": {
            en: "Equipment Ref",
            es: "Ref. Equipo"
        },
        "installation_label": {
            en: "Installation Date",
            es: "Fecha instalación"
        },
        "ceco_label": {
            en: "CECO",
            es: "CECO"
        },
        "price_label": {
            en: "Price",
            es: "Precio"
        },
        "sociedad_label": {
            en: "Company",
            es: "Sociedad"
        },
        "dgt_label": {
            en: "Area of Responsibility",
            es: "Área de Responsabilidad"
        },
        "province_label": {
            en: "Province",
            es: "Provincia"
        },
        "installation_address_label": {
            en: "Installation Address",
            es: "Dirección de instalación"
        },
        "installation_date_label": {
            en: "Installation Date",
            es: "Fecha de instalación"
        },
        "see_all_label": {
            en: "see all",
            es: "ver todo"
        },
        "search_placeholder": {
            en: "search",
            es: "buscar"
        },
        "see_more_label": {
            en: "See more",
            es: "Ver más"
        },
        "see_less_label": {
            en: "See less",
            es: "Ver menos"
        },
        "documentation_label": {
            en: "documentation",
            es: "documentación"
        },
        "service_label": {
            en: "service",
            es: "servicio"
        },
        "service_done_label": {
            en: "service done",
            es: "servicio hecho"
        },
        "last_service_label": {
            en: "Last Service",
            es: "Servicio Anterior"
        },
        "next_service_label": {
            en: "Next Service",
            es: "Próximo Servicio"
        },
        "name_label": {
            en: "Name",
            es: "Nombre"
        },
        "close_label": {
            en: "Close",
            es: "Cerrar"
        },
        "report_a_problem_label": {
            en: "Report A Problem",
            es: "Reportar un problema"
        },
        "your_bottles_label": {
            en: "Your Bottles",
            es: "Tus Botellas"
        },
        "quantity_label": {
            en: "Quantity",
            es: "Cantidad"
        },
        "new_order_label": {
            en: "New Order",
            es: "Nuevo orden"
        },
        "min_order_label": {
            en: "Min Order",
            es: "Pedido mínimo"
        },
        "max_order_label": {
            en: "Max Order",
            es: "Pedido Máximo"
        },
        "last_order_label": {
            en: "Last Order",
            es: "Orden Anterior"
        },
        "order_number_label": {
            en: "Order Number",
            es: "Número de pedido"
        },
        "impact_heading_label": {
            en: "Impacto positivo de",
            es: "Impacto positivo de"
        },
        "number_of_people_label": {
            en: "Number of people",
            es: "Número de personas"
        },
        "people_label": {
            en: "People",
            es: "personas"
        },
        "centres_label": {
            en: "Centro",
            es: "Centro"
        },
        "address_label": {
            en: "Address",
            es: "Dirección"
        },
        "city_label": {
            en: "City",
            es: "Ciudad"
        },
        "consumers_label": {
            en: "Consumers",
            es: "Comensales"
        },
        "for_label": {
            en: "for",
            es: "por"
        },
        "day_label": {
            en: "Day",
            es: "Día"
        },
        "month_label": {
            en: "Month",
            es: "Mes"
        },
        "year_label": {
            en: "Year",
            es: "Año"
        },
        "daily_label": {
            en: "Daily",
            es: "Diario"
        },
        "monthly_label": {
            en: "Mensual",
            es: "Mensual"
        },
        "annual_label": {
            en: "Anual",
            es: "Anual"
        },
        "since_install_label": {
            en: "Since KMZero was installed",
            es: "Desde KMZero instalo"
        },
        "duration_label": {
            en: "Período",
            es: "Período"
        },
        "positive_impact_label": {
            en: "Positive Impact",
            es: "Impacto Positivo"
        },
        "plastic_bottles_saved_label": {
            en: "Bottles Saved",
            es: "Botellas ahorradas"
        },
        "plastic_saved_label": {
            en: "Plastic Saved",
            es: "Plástico ahorrado"
        },
        "co2_emissions_saved_label": {
            en: "CO2 Emisiones Saved",
            es: "Emisiones CO2 ahorradas"
        },
        "litres_of_water_saved_label": {
            en: "Water saved",
            es: "Agua ahorrada"
        },
        "units_label": {
            en: "units",
            es: "unidades"
        },
        "kg_label": {
            en: "kilograms",
            es: "kilogramos"
        },
        "tonnes_label": {
            en: "tonnes",
            es: "toneladas"
        },
        "litres_label": {
            en: "litres",
            es: "litros"
        },
        "download_label": {
            en: "Download",
            es: "descargar"
        },
        "download_info_label": {
            en: "Download Information",
            es: "Descargar informe"
        },
        "share_label": {
            en: "Share",
            es: "compartir"
        },
        "sorry_label": {
            en: "sorry",
            es: "lo siento"
        },
        "costing_label": {
            en: "Billing Info",
            es: "Información de facturación"
        },
        "equipment_info_label": {
            en: "Equipment Info",
            es: "Información del equipo"
        },
        "history_label": {
            en: "History",
            es: "Historia"
        },
        "no_history_label": {
            en: "You will see equipment history here when there are uninstalls and reinstalls",
            es: "Verás la historia del equipo aquí cuando haya desinstalaciones y reinstalaciones"
        },
        "not_on_mobile_notice": {
            en: "This app is not yet available on mobile. Please visit on your computer.",
            es: "Esta aplicación aún no está disponible en dispositivos móviles. Visite en su computadora."
        },
        "search_bar_placeholder_label": {
            en: "Search using CECO, City, Centre, Equipment ID, Equipment type or Society",
            es: "Buscar usando CECO, Ciudad, Centro, ID de equipo, tipo de equipo o Sociedad"
        },
        "in_label": {
            en: "in",
            es: "en"
        },
        "city_search_category_label": {
            en: "Cities",
            es: "Ciudades"
        },
        "dgt_search_category_label": {
            en: "Areas of Responsibility",
            es: "Áreas de Responsabilidad"
        },
        "customer.id_search_category_label": {
            en: "Customers",
            es: "Clientes"
        },
        "ca_search_category_label": {
            en: "Autonomous Communities",
            es: "Comunidades Autónomas"
        },
        "province_search_category_label": {
            en: "Provinces",
            es: "Provincias"
        },
        "sociedad_search_category_label": {
            en: "Companies",
            es: "Sociedades"
        },
        "id_search_category_label": {
            en: "Equipment ID",
            es: "ID de equipo"
        },
        "equipment.name_search_category_label": {
            en: "Equipment Name",
            es: "Nombre de equipo"
        },
        "equipment_search_category_label": {
            en: "Equipment",
            es: "Equipo"
        },
        "ceco_search_category_label": {
            en: "CECO",
            es: "CECO"
        },
        "centres_search_category_label": {
            en: "Centres",
            es: "Centros"
        },
    }
}