import $ from "jquery";
import Vue from "vue";
import _ from "lodash";

export const layout = {
    namespaced: true,
    state: {
        beta: true,
        isNew: false,
        backgrounds: [
            require("@/assets/backgrounds/background-1.jpg"),
            require("@/assets/backgrounds/background-2.jpg"),
            require("@/assets/backgrounds/background-3.jpg")
        ],
        logo: {
            original: require("@/assets/kmzero-logo-app.png"),
            white: require("@/assets/kmzero-brand-logo-white.png"),
            green: require("@/assets/kmzero-logo-green.png"),
            mark: require("@/assets/kmz_square_logo.png"),
            show: true,
            onlymark: false
        },
        languages: {
            en: {
                name: "en",
                title: "English",
                in: {
                    es: "Inglés"
                }
            },
            es: {
                name: "es",
                title: "Español",
                in: {
                    en: "Spanish"
                }
            }
        },
        currentLanguage: "es",
        working: true,
        navbar: {
            show: true,
            links: [
                {
                    title: "My",
                    name: `/my`,
                    icon: "tint",
                    admin: true,
                },
                {
                    title: "Cuentas",
                    name: `/accounts`,
                    icon: "address-book",
                    admin: true,
                },
                {
                    title: "Fiscals",
                    name: `/fiscals`,
                    icon: "briefcase",
                    admin: true,
                },
                {
                    title: "Commercials",
                    name: `/commercials`,
                    icon: "store",
                    admin: true,
                },
                {
                    title: "Leads",
                    name: `/leads`,
                    icon: "bullhorn",
                    admin: true,
                },
                {
                    title: "Visitas",
                    name: `/visits`,
                    icon: "shuttle-van",

                },
                {
                    title: "Equipos",
                    name: `/equipments`,
                    icon: "filter",
                    admin: true,
                },
                {
                    title: "Personal",
                    name: `/staff/20/0/forward`,
                    icon: "users",
                    admin: true,
                },
                {
                    title: "Departments",
                    name: `/departments`,
                    icon: "inbox",
                    admin: true,
                },
                {
                    title: "Grupos",
                    name: `/groups/20/0/forward`,
                    icon: "sitemap",
                    admin: true,
                },
                // {
                //     title: "reports",
                //     name: `/reports`,
                //     icon: "chart-area"
                // },
            ]
        },
        window: {
            height: 0,
            width: "100%",
            navHeight: 0,
            frameTop: 0
        },
        notif: {
            type: "working",
            icon: "spinner",
            message: "",
            show: false
        },
        singulars: {
            historyAndNotes: false
        },
        showBottomBar: false
    },
    getters: {
        getBackgrounds: (state) => { return state.backgrounds; },
        getLogo: (state) => { return state.logo; },
        getWorking: state => { return state.working; },
        getWindow: state => { return state.window; },
        getLinks: (state) => { return state.navbar.links; },
        getWindowHeight: state => { return `height: ${state.window.height}px; max-height: ${state.window.height}px;`; },
        getNotif: state => { return state.notif; },
        outerWindowStyle: state => { return  state.navbar.show ? `padding-top: ${state.window.navHeight}px;` : 'padding-top: 0px;'; },
        getSingulars: state => { return state.singulars; },
        getNavbar: (state) => { return state.navbar; },
        getShowBottomBar: (state) => { return state.showBottomBar; },
        getBeta: (state) => { return state.beta; },
        getIsNew: (state) => { return state.isNew; }
    },
    mutations: {
        SET_SHOW_BOTTOM_BAR: (state, val) => {
            state.showBottomBar = val;
        },
        SET_WORKING: (state, val) => {
            state.working = val;
        },
        SET_WINDOW_HEIGHT (state, height) {
            state.window.height = height;
        },
        SET_NAV_HEIGHT (state, height) {
            state.window.navHeight = height;
        },
        SET_NOTIF: (state, {show, type, icon, message}) => {
            Vue.set(state, "notif", {
                show: show || false,
                type: type || "",
                icon: icon || "",
                message: message || ""
            });
        },
        SET_FRAME_TOP: (state, frameTop) => {
            state.window.frameTop = frameTop;
        },
        SET_SINGULARS: (state, {name, id}) => {
            state.singulars[name] = id;
        },
        SET_NAV: (state, nav) => {
            _.each(nav, (val, key) => {
                Vue.set(state.navbar, key, val);
            })
        },
        SET_LOGO_SETTINGS: (state, settings) => {
            _.each(settings, (val, key) => {
                Vue.set(state.logo, key, val);
            })
        },
        SET_ISNEW: (state, val) => {
            state.isNew = val;
        }
    },
    actions: {
        setWorking: (context, val) => {
            context.commit("SET_WORKING", val);
        },
        // Defines the action to calculate and set the window height and related CSS variables, handling the case when the navbar might not exist.
        setWindowHeight: function(context) {
            let navbarHeight = 0, submenuHeight = 0, frameTop;

            // Check if the navbar show state is false or if the navbar element does not exist.
            if(context.state.navbar.show === false || !$(".navbar").length) {
                navbarHeight = 0;
                submenuHeight = 0;
            } else {
                // If navbar exists, calculate its height and the height of any submenu.
                let navbar = $(".navbar.is-fixed-top");
                let submenu = $(".submenu");
                
                if(navbar.length) {
                    context.commit("SET_NAV_HEIGHT", navbar.get(0).scrollHeight);
                    navbarHeight = navbar.innerHeight();
                }
                
                if(submenu.length) {
                    submenuHeight = submenu.get(0).scrollHeight;
                }
            }
            
            frameTop = (navbarHeight + submenuHeight);

            // Sets CSS variables for the heights of navbar, submenu, and the top margin for the main frame.
            document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
            document.documentElement.style.setProperty('--submenu-height', `${submenuHeight}px`);
            document.documentElement.style.setProperty('--frame-top', `${frameTop}px`);

            console.log({navbarHeight, submenuHeight, frameTop});

            const windowHeight = window.innerHeight;

            // Calculates the height available for the main content frame.
            const frameHeight = windowHeight - frameTop;

            console.log(`${windowHeight} - (${navbarHeight} + ${submenuHeight}) = ${frameHeight}`);

            // Commits the calculated frame height and top margin to the Vuex store.
            context.commit("SET_WINDOW_HEIGHT", frameHeight);
            context.commit("SET_FRAME_TOP", frameTop);
        },

        setNotif: function(context, {show, type, icon, message, delay}) {
            context.commit("SET_NOTIF", {show, type, icon, message})
            //console.log({show, type, icon, message, delay})
            if(delay) {
                setTimeout(function() {
                    context.commit("SET_NOTIF", {show: false});
                }, delay)
            }
        },
        setSingulars: function (context, {name, id}) {
            context.commit("SET_SINGULARS", {name, id});
        },
        showNav: function(context, show) {
            context.commit("SET_NAV", { show });
        },
        toggleLogo: function(context, show) {
            context.commit("SET_LOGO_SETTINGS", { show });
        },
        setShowBottomBar: function(context, val) {
            context.commit("SET_SHOW_BOTTOM_BAR", val);
        },
        setTitle: function(context, opts) {
            var { page, account, override } = opts;
            if(override) return document.title = override;
            var title = `${page} | `;
            if(account) title = title + account + " x ";
            title = title + "Agua KMZERO";
            document.title = title;
        },
        setIsNew: function(context, val) {
            context.commit("SET_ISNEW", val);
        }
    }
}