<template>
    <div class="background-overlay cover-image">
    <!-- <div class="background-overlay cover-image" :style="background"> -->
        <div class="darkoverlay" v-if="dark"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "background-overlay",
    props: {
        dark: {
            type: Boolean,
            default: true
        }
    },
    computed: {
    ...mapGetters({
      getBackgrounds: "layout/getBackgrounds"
    }),
    background: function() {
      return { backgroundImage: `url(${this.getBackgrounds[2]})`} 
    }
  },
}
</script>

<style lang="scss" scoped>

    .background-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-color: $newBlue;
        max-width: 100vw;

        .darkoverlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            max-width: 100vw;
            background-color: rgba(black, 0.2);

        }

    }

</style>