// helper.js
export const isLocalhost = () => {
    return window.location.hostname === "localhost";
}

export const getUrlQuery = (name) => {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const myCookies = {
    set: (cname, cvalue, exdays = "365") => {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    formatCookieVal: (cvalue) => {
        // return booleans and numbers and null or undefined as is
        switch(cvalue) {
            case "true":
                return true;
            case "false":
                return false;
            case "null":
                return null;
            case "undefined":
                return undefined;
            case "NaN":
                return NaN;
            default:
                return cvalue;
        }
    },
    get: (cname = "*") => {
        if(cname === "*") {
            // iterate through all cookies and return an object
            let cookies = {};
            let cookieArray = document.cookie.split(';');
            for(let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i].split("=");
                let val = myCookies.formatCookieVal(cookie[1]);
                cookies[cookie[0]] = val;
            }
            return cookies;
        }

        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                let cookieValue = c.substring(name.length, c.length);
                return myCookies.formatCookieVal(cookieValue);
            }
        }
        return undefined;
    },
    delete: (cname) => {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
}

// if it's localhost or the query string has a "dev" parameter, then we're in dev mode and we should expose the helper functions to the window object
if(isLocalhost() || getUrlQuery("dev") === "true") {
    console.log("===== DEV MODE =====");
    // set Dev cookie
    myCookies.set("dev", true);
    window.myCookies = myCookies;
    window.getUrlQuery = getUrlQuery;
    window.isLocalhost = isLocalhost;
}

if(getUrlQuery("dev") === "false") {
    console.log("===== PRODUCTION MODE =====");
    // set Dev cookie
    myCookies.set("dev", false);
    window.myCookies = undefined;
    window.getUrlQuery = undefined;
    window.isLocalhost = undefined;
}