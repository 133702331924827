import Vue from 'vue'
import Vuex from 'vuex'
import { lang } from "./modules/lang.module";
import { layout } from "./modules/layout.module";
import { auth } from "./modules/auth.module";
import { fbase } from "./modules/firebase.module";
import { user } from "./modules/user.module";
import { airtable } from "./modules/airtable.module";
import { api } from "./modules/api.module";
import { algolia } from "./modules/algolia.module";
import { sheets } from "./modules/sheets.module";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    api,
    lang,
    layout,
    auth,
    user,
    fbase,
    airtable,
    algolia,
    sheets
  }
})

window.store = store;

export default store;
