import {truncate, titleCase, fullName, simpleDate, formatDate, initials, translate, char} from './text';


export default {
     install(Vue) {
         Vue.filter('truncate', truncate);
         Vue.filter('titleCase', titleCase);
         Vue.filter('fullName', fullName);
         Vue.filter('initials', initials);
         Vue.filter('simpleDate', simpleDate);
         Vue.filter('formatDate', formatDate);
         Vue.filter('translate', translate);
         Vue.filter('char', char);
     }
}