import moment from "moment";
import VueCookies from "vue-cookies";
import { terms } from "@/assets/js/lang.js";

export function truncate (text, start = 0, length = 100, suffix = "...") {
    return text.substr(start, length).trim() + suffix;
}

export function translate (term, lang) {
    if(!lang) lang = VueCookies.get("lang") || "en";

    if(!term) return "";

    var foundTerm = terms[term];
    return foundTerm ? foundTerm[lang] || term : term

}

export function titleCase(str) {
    if(!str) return '';
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
}

export function initials(fullname) {
    if(!fullname) return "";
    var name = fullname.split(" ");
    var inits = name[0].charAt(0);
    if(name[1]) inits = inits + name[1].charAt(0);
    return inits;
}

export function fullName(name) {
    var full = "";
    if(name.first) full = name.first;
    if(name.first && name.last) full = full + " ";
    if(name.last) full = full + name.last;

    return full;
}

export function char(text, length = 1) {
    return text[length];
}

export function simpleDate(ts) {
    if(!ts) return "";
    if(ts) return moment(ts).format("DD MM YYYY HH:mm a");
}

export function formatDate(date, format = "DD MM YYYY HH:mm a") {

    //console.log(date, format);

    if(!date.toDate) return "invalid date";

    var d = date.toDate();

    return moment(d).format(format);
     
}