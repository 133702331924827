<template>
    <span v-if="icon" class="fa-icon" @click="$emit('click')">
        <font-awesome-layers :class="{'fa-2x': counter >= 0 }">
            <font-awesome-icon 
                :icon="[type, icon]" 
                :spin="spin"
                :class="[getColor, {'fa-flip-horizontal': flipHorizontal}]"
            ></font-awesome-icon>
             <font-awesome-layers-text v-if="counter >= 0" :value="counter" />
        </font-awesome-layers>
        <slot></slot>
    </span>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
library.add(fab);
library.add(far);
library.add(fas);

export default {
    name: "FAIcon",
    components: {
        FontAwesomeIcon,
        FontAwesomeLayers,
        FontAwesomeLayersText
    },
    props: {
        icon: {
            type: String,
            default: "question-circle"
        },
        type: {
            type: String,
            default: "fas"
        },
        spin: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ""
        },
        flipHorizontal: {
            type: Boolean,
            default: false
        },
        counter: Number
    },
    computed: {
        getColor: function() {
            switch(this.color) {
                case "working":
                    return "has-text-link";
                
                case "success":
                    return "has-text-success";

                case "fail":
                    return "has-text-danger";
                
                case "gold":
                    return "has-text-connections-gold";

                default:
                    return ""
            }
        }
    },
    mounted: function() {
        //console.log("mounted", this.$options.name);
        //console.log("icon", this.icon);
    }
}
</script>

<style lang="scss" scoped>

    .fa-icon {
        margin: 0px;

        svg {
            overflow: hidden;
        }

        .fa-layers-text {
            color: white;
            font-size: 0.6rem;
        }
    }
</style>