
export const user = {
    namespaced: true,
    state: {
        uid: null,
        user: {
            uid: null,
            displayName: "",
            photoURL: ""
        },
        staff: null,
        department: null,
        users: null
    },
    getters: {
        getUser: (state) => { return state.user; },
        getStaff: (state) => { return state.staff; },
        getUID: (state) => { return state.user.uid; },
        getName: (state) => (part) => {
            if(part) {
                switch(part) {
                    case "first":
                        return state.user.displayName.split(" ")[0];
                    
                    case "second":
                        return state.user.displayName.split(" ")[1];   
                }
            } else {
                return state.user.displayName;
            }
        },
        getSudo: (state) => {
            return state.user.sudo;
        },
        getAdmin: (state) => {
            return state.user.admin;
        },
        getUserRef: (state) => {
            return state.user.ref;
        }
    },
    mutations: {
        SET_USER: (state, user) => {
            state.user = user;
            state.user.ref = state.users.doc(user.uid);
        },
        SET_STAFF: (state, staff) => {
            state.staff = staff;
        },
        UNSET_USER: (state) => {
            state.user =  {
                displayName: "",
                photoURL: "",
                uid: null
            }
        },
        SET_UID: (state, uid) => {
            state.uid = uid;
        },
        SETUP_FIREBASE: (state, ref) => {
            state.users = ref;
        }
    },
    actions: {
        setupFirebase: function(context, ref) {
            context.commit("SETUP_FIREBASE", ref);
        },
        setUser: function(context, user) {
            return new Promise((resolve, reject) => {
                context.dispatch("layout/setWorking", true, { root: true });
                if(context.state.uid != null) {
                    // eslint-disable-next-line no-undef
                    context.state.users.doc(context.state.uid).get()
                    .then(function(snapshot) {
                        if(snapshot.exists) {
                            var userData = snapshot.data();
                            context.commit("SET_USER", userData);
                            context.dispatch("resolveStaff").then(() => {
                                resolve();
                            }).catch(err => {
                                console.error(err);
                                reject(err);
                            });
                        } else {
                            // eslint-disable-next-line no-undef
                            context.state.users.doc(user.uid).set(user)
                            .then(() => {
                                context.commit("SET_USER", user);
                                context.dispatch("resolveStaff").then(() => {
                                    resolve();
                                }).catch(err => {
                                    console.error(err);
                                    reject(err);
                                });
                            })
                        }
                    })
                }
            })
            
        },
        resolveStaff: function(context) {
            return new Promise((resolve, reject) => {
                if(context.state.user.staff) {
                    context.state.user.staff.get().then((staffSnap) => {
                        if(staffSnap.exists) {
                            var staff = staffSnap.data();
                            staff.ref = staffSnap.ref;
                            staff.id = staffSnap.id;
                            context.commit("SET_STAFF", staff);
                            resolve(staff);
                        } else {
                            resolve(false);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);
                    })
                } else {
                    resolve(false);
                }
            })
        },
        unsetUser: (context) => {
            context.commit("UNSET_USER");
        },
        setUID: (context, uid) => {
            context.commit("SET_UID", uid);
        },
        // eslint-disable-next-line no-unused-vars
        setupRefs: (context) => {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                // var fs = context.rootGetters["fbase/getFS"];
                // var layout = fs.layouts.doc(context.state.uid);
                // context.dispatch("layout/setupFirebase", layout, { root: true }).then(() => {
                //     resolve();
                // }).catch(err => {
                //     console.error(err);
                //     reject(err);
                // });
                resolve();
            })
            
        },
    },
    
}