import router from '../../router';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, signOut, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBMAbftqUcxz7xUE9vDNi0x6pajBh47I38",
    authDomain: "my-kmzero.firebaseapp.com",
    projectId: "my-kmzero",
    storageBucket: "my-kmzero.appspot.com",
    messagingSenderId: "198048815027",
    appId: "1:198048815027:web:2e16200578b09a25ea9dc6",
    measurementId: "G-NYFG9R4ENK"
};

const app = initializeApp(firebaseConfig, "auth");
const fbAuth = getAuth(app);


export const auth = {
    namespaced: true,
    state: {
        loggedIn: false,
        nextPage: null,
        nextPath: "/",
        loggingIn: false,
        loggingOut: false,
        loginRequested: false,
        authToken: null,
        auth: fbAuth,
        user: null,
    },
    getters: {
        isLoggedIn: state => { return state.loggedIn; },
        loggingOut: state => { return state.loggingOut; },
        getNextPage: state => { return state.nextPage; },
        getNextPath: state => { return state.nextPath; }
    },
    mutations: {
        SET_LOGGEDIN: (state, status) => {
            state.loggedIn = status;
        },
        SET_NEXTPAGE: (state, name) => {
            state.nextPage = name;
        },
        SET_NEXTPATH: (state, name) => {
            state.nextPath = name;
        },
        SET_LOGINREQUESTED: (state, val) => {
            state.loginRequested = val;
        },
        SET_LOGGINGIN: (state, val) => {
            state.loggingIn = val;
        },
        SET_LOGGINGOUT: (state, status) => {
            state.loggingOut = status;
        },
        SET_AUTH_TOKEN: (state, token) => { 
            state.token = token;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        SET_AUTH: (state, auth) => {
            state.auth = auth;
        }
    },
    actions: {
        setNextPage: (context, page) => {
            context.commit("SET_NEXTPAGE", page);
        },
        setNextPath: (context, path) => {
            context.commit("SET_NEXTPATH", path);
        },
        setLoggingOut: (context, status) => {
            context.commit("SET_LOGGINGOUT", status);
        },
        setLoggedIn: (context, status) => {
            context.commit("SET_LOGGEDIN", status);
            
            if(status == true) {
                var nextPath = context.state.nextPath || "/";
                if(router.currentRoute.path != nextPath) {
                    router.push({path: nextPath}).catch(err => { console.error(err); });
                }
            } else {
                context.commit("SET_AUTH_TOKEN", false);
                if(router.currentRoute.name != "login") router.push({name: "login"});
            }

        },
        getToken: function(context, { username, password } = {}) {
            return new Promise(async (resolve, reject) => {
                var authRoute = context.rootGetters["api/get"]("auth.getToken");
                console.log("getToken", { username, password });

                if(authRoute) {
                    context.dispatch("api/fetch", { ...authRoute, data: { username, password }}, {root: true})
                    .then(resp => {
                        if(resp.status === 200 && resp.success && resp.data) {
                            context.commit("SET_AUTH_TOKEN", resp.data.token);
                            console.log(resp.data.token);
                            resolve(resp.data.token);
                        } else {
                            reject("Auth error");
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        reject(err);
                    })
                }
            })
        },
        startLogin: function(context, { token } = {}) {
            return new Promise(async (resolve, reject) => {
                context.dispatch("layout/setWorking", true, { root: true });

                context.commit("SET_AUTH_TOKEN", token);

                signInWithCustomToken(context.state.auth, token)
                .then(authed => {
                    console.log({authed});
                    context.dispatch("checkLoginState");
                    resolve(authed.user);
                })
                .catch((error) => {
                    
                    context.dispatch("layout/setWorking", false, { root: true });

                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    var credential = error.credential;
                    // ...
                    console.error(error);
                    console.log(errorCode, errorMessage, email, credential);
                    context.dispatch("startLogout");
                });
                
            })
            
        },
        startLogout: function(context) {
            context.dispatch("layout/setWorking", true, { root: true });
            context.commit("SET_LOGGINGOUT", true);
            setTimeout(function() {
                signOut(context.state.auth).then(function() {
                    context.dispatch("airtable/reset", ["accounts"], { root: true });
                    context.dispatch("fbase/reset", [], { root: true });
                    context.dispatch("user/setUID", null, { root: true });
                    context.dispatch("setLoggedIn", false);
                    context.dispatch("layout/setWorking", false, { root: true });
                })
            }, 500);
        },
        // eslint-disable-next-line no-unused-vars
        checkLoginState: async function(context) {

            context.dispatch("layout/setWorking", true, { root: true });

            var unsubscribe = onAuthStateChanged(context.state.auth, async (user) => {
                unsubscribe();

                context.dispatch("layout/setWorking", false, { root: true });
                

                if(user != null) {
                    console.log(user.toJSON());
                    context.commit("SET_LOGGINGOUT", false);
                    context.commit("SET_LOGGINGIN", true);
                    context.dispatch("fbase/getUserData", user.uid, {root: true}).then(() => {
                        context.dispatch("user/setUID", user.uid, { root: true });
                        context.dispatch("setLoggedIn", true);
                        context.dispatch("layout/setWorking", false, { root: true });
                    })
                    .catch(err => {
                        console.error(err);
                        context.dispatch("startLogout");
                    })
                    
                    // context.dispatch("user/setUser", user.toJSON(), { root: true }).then(() => {
                    //     context.dispatch("setLoggedIn", true);
                    //     context.dispatch("layout/setWorking", false, { root: true });
                    // }).catch(err => {
                    //     console.error(err);
                    // });

                    
                } else {
                    context.dispatch("setLoggedIn", false);
                }
            });
        }
    }
}