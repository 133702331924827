import _ from "lodash"

const loc = window.location.host;
const base = loc.includes("localhost") ? "http://localhost:8829" : "https://api.aguakmzero.com";


export const api = {
    namespaced: true,
    state: {
        base: base,
        auth: {
            getToken: {
                method: "POST",
                route: "/my/auth/getToken",
                type: "json"
            },
            checkLogin: {
                method: "POST",
                route: "/my/auth/checkLogin",
                type: "json"
            }
        }
    },
    getters: {
        get: (state) => (path) => {
            var r = _.get(state, path, null);
            if(!r) return null;
            r.url = base + r.route;
            return r;
        }
    },
    mutations: {

    },
    actions: {
        fetch: function(context, { url, type, method, data}) {
            return new Promise((resolve, reject) => {

                console.log({url, type, method, data});

                var options = {
                    method
                }

                if(type === "json") {
                    options.headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }

                    options.body = JSON.stringify(data);
                }

                fetch(url, options)
                .then( resp => resp.json()).then( d => {
                    console.log(d);
                    resolve({ ...d });
                })
                .catch( err => {
                    console.error(err);
                    reject(err);
                })
            })
        }
    }
}